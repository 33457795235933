<script>
import _get from 'lodash/get';
import { required } from 'vuelidate/lib/validators';
import BinaryFeedbackField from '@school/components/BinaryFeedbackField.vue';

export default {
  components: { BinaryFeedbackField },
  props: {
    isUpdating: {
      type: Boolean,
      default: false,
    },
    training: {
      type: Object,
      required: true,
    },
    trainingItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isSending: false,
      showCommentField: false,
      userFeedback: {
        score: _get(this, 'trainingItem.progression.specific_content.feedback.score'),
        comment: _get(this, 'trainingItem.progression.specific_content.feedback.comment'),
      },
    };
  },
  computed: {
    canGiveFeedback() {
      if (!this.training.feedback_options.enabled) {
        return false;
      }

      if (this.$store.getters['store/hasPercentPlan']) {
        return false;
      }

      if (
        this.training.feedback_options.options.type !== 'RANGE'
        && !this.$store.getters['store/hasExpertPlan']
      ) {
        return false;
      }

      return true;
    },
    canComment() {
      return (
        this.training.feedback_options.options.comment_enabled
        && this.$store.getters['store/hasExpertPlan']
      );
    },
    hasGivenFeedback() {
      return _get(
        this.trainingItem,
        'progression.specific_content.feedback.score',
      ) != null;
    },
    hasGivenComment() {
      return _get(
        this.trainingItem,
        'progression.specific_content.feedback.comment',
      ) != null;
    },
  },
  validations: {
    userFeedback: {
      score: {
        required,
      },
    },
  },
  watch: {
    isUpdating(value) {
      if (!value && this.isSending) {
        this.isSending = false;
        this.showCommentField = false;
      }
    },
  },
  methods: {
    onRateChange(value) {
      if (this.hasGivenFeedback) {
        this.$buefy.dialog.confirm({
          type: 'is-danger',
          title: 'Attention !',
          message: 'Vous avez déjà donné votre avis sur cette leçon, êtes-vous sûr(e) de vouloir le changer ?',
          focusOn: 'cancel',
          confirmText: 'Oui',
          onConfirm: () => this.sendFeedback(value),
          onCancel: () => {
            const oldValue = this.userFeedback.score;
            this.userFeedback.score = value;
            this.$nextTick(() => (this.userFeedback.score = oldValue));
          },
        });

        return;
      }

      this.sendFeedback(value);
    },
    sendFeedback(value) {
      if (value != null) {
        this.userFeedback.score = value;

        if (this.canComment && this.showCommentField) {
          return;
        }
      }

      this.$emit('done', {
        ...this.userFeedback,
        created_at: this.$moment().utc().format('YYYY-MM-DD HH:mm:ss'),
      });
      this.isSending = true;
    },
  },
  created() {
    this.showCommentField = !this.hasGivenFeedback;
  },
};
</script>

<template>
  <div
    v-if="canGiveFeedback"
    class="
      container is-relative px-3 py-15 bradius-8
      has-background-light is-custom
    "
  >
    <b-loading v-if="isUpdating" :is-full-page="false" active />

    <div class="box has-text-centered mx-auto p-5 mw-520">
      <h3 class="title is-custom is-5 mb-3">
        {{ training.feedback_options.options.label }}
      </h3>

      <form @submit.prevent="dataIsValid(isUpdating) && sendFeedback()">
        <b-field
          v-bind="$getErrorProps($v.userFeedback.score, ['required'])"
        >
          <b-rate
            v-if="training.feedback_options.options.type == 'RANGE'"
            class="is-justify-content-center"
            :value="userFeedback.score"
            :icon-pack="training.feedback_options.options.values.k1.icon_type"
            :icon="training.feedback_options.options.values.k1.icon"
            size="is-medium"
            spaced
            @change="onRateChange"
          />
          <BinaryFeedbackField
            v-else
            :value="userFeedback.score"
            :options="training.feedback_options.options.values"
            @input="onRateChange"
          />
        </b-field>
        <template v-if="canComment">
          <div v-if="hasGivenFeedback && !showCommentField" class="field py-3">
            <template v-if="hasGivenComment">
              <p class="has-text-dark has-text-weight-bold">
                Votre précédent avis :
              </p>
              <p>{{ userFeedback.comment }}</p>
            </template>
            <p :class="{'mt-2': hasGivenComment}">
              <b-button
                type="is-text"
                size="is-small"
                class="tdecoration-underline"
                @click="showCommentField = true"
              >
                <template v-if="hasGivenComment">
                  Éditer mon avis
                </template>
                <template v-else>
                  Ajouter un avis
                </template>
              </b-button>
            </p>
          </div>
          <template v-else>
            <b-field label="Détailler mon évaluation">
              <b-input
                class="mw-640 mx-auto"
                custom-class="mnh-auto mh-auto h-80"
                v-model="userFeedback.comment"
                type="textarea"
              />
            </b-field>
            <div class="field">
              <b-button
                type="is-primary"
                class="is-custom"
                native-type="submit"
              >
                Donner mon avis
              </b-button>
            </div>
          </template>
        </template>
      </form>
    </div>
  </div>
</template>
