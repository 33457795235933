<script>
import VueSimpleEmbed from '@shared/components/VueSimpleEmbed.vue';

export default {
  components: { VueSimpleEmbed },
  props: {
    answer: {
      type: Object,
      required: true,
    },
  },
};
</script>

<template>
  <div v-if="answer.content.length > 0">
    <slot name="delete" />

    <figure
      v-if="answer.content[0].type === 'image'"
      key="image"
      class="quiz-image"
      :class="{'ml-auto': !!$slots.delete}"
    >
      <img :src="answer.content[0].data.url" :alt="answer.answer">
    </figure>
    <figure
      v-else-if="answer.content[0].type === 'video'"
      key="video"
      :class="{
        'quiz-video': answer.content[0].data.type != 'embed',
        'ml-auto': !!$slots.delete
      }"
    >
      <vue-plyr v-if="answer.content[0].data.type != 'embed'">
        <video :src="answer.content[0].data.url" />
      </vue-plyr>
      <VueSimpleEmbed
        v-else
        :url="answer.content[0].data.url"
        parser="plyr"
      />
    </figure>
    <figure
      v-else-if="answer.content[0].type === 'audio'"
      key="audio"
      class="quiz-audio"
      :class="{'ml-auto': !!$slots.delete}"
    >
      <vue-plyr v-if="answer.content[0].data.type != 'embed'">
        <audio :src="answer.content[0].data.url" />
      </vue-plyr>
      <VueSimpleEmbed
        v-else
        :url="answer.content[0].data.url"
      />
    </figure>
  </div>
</template>
