<script>
export default {
  props: {
    maxHeight: {
      type: Number,
      default: 200,
    },
  },
  mounted() {
    if (!this.$el) {
      return;
    }

    const $el = this.$el.querySelector('textarea');
    $el.style.resize = 'none';
    $el.style.height = 'auto';
    this.onInput = () => {
      $el.style.minHeight = '';
      $el.style.overflow = 'hidden';
      let height = $el.scrollHeight;

      if (this.maxHeight > 0 && this.maxHeight < height) {
        height = this.maxHeight;
      }

      $el.style.minHeight = `${height}px`;
      $el.style.overflow = '';
    };

    this.onInput();
    $el.addEventListener('input', this.onInput);
    this.$on('hook:destroyed', () => $el.removeEventListener(
      'input', this.onInput,
    ));
  },
  updated() {
    this.onInput();
  },
  render() {
    return this.$scopedSlots.default({});
  },
};
</script>
