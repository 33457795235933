import ContentBlockRichTextView from '@shared/components/ContentBlocks/Views/RichText.vue';
import ContentBlockSeparatorView from '@shared/components/ContentBlocks/Views/Separator.vue';
import ContentBlockHTMLView from '@shared/components/ContentBlocks/Views/HTML.vue';
import ContentBlockToggleView from '@shared/components/ContentBlocks/Views/Toggle.vue';
import ContentBlockMarkdownView from '@shared/components/ContentBlocks/Views/Markdown.vue';
import ContentBlockURLView from '@shared/components/ContentBlocks/Views/URL.vue';
import ContentBlockTrainingItemURLView from '@shared/components/ContentBlocks/Views/TrainingItemURL.vue';
import ContentBlockResourceView from '@shared/components/ContentBlocks/Views/Resource.vue';
import ContentBlockOEmbedView from '@shared/components/ContentBlocks/Views/OEmbed.vue';
import ContentBlockImageView from '@shared/components/ContentBlocks/Views/Image.vue';
import ContentBlockVideoView from '@shared/components/ContentBlocks/Views/Video.vue';
import ContentBlockAudioView from '@shared/components/ContentBlocks/Views/Audio.vue';
import ContentBlockPDFViewerView from '@shared/components/ContentBlocks/Views/PDFViewer.vue';
import ContentBlockEmbedView from '@shared/components/ContentBlocks/Views/Embed.vue';
import ContentBlockEmbedSimpleView from '@shared/components/ContentBlocks/Views/EmbedSimple.vue';
// import ContentBlockEmbedoView from '@shared/components/ContentBlocks/Views/Embedo.vue';
import ContentBlockHeadingView from '@shared/components/ContentBlocks/Views/Heading.vue';
import ContentBlockCodeBlockView from '@shared/components/ContentBlocks/Views/CodeBlock.vue';
import ContentBlockNotificationView from '@shared/components/ContentBlocks/Views/Notification.vue';
import TiktokOEmbed from '@shared/components/ContentBlocks/Views/TiktokOEmbed.vue';

export default {
  video: ContentBlockVideoView,
  audio: ContentBlockAudioView,
  resource: ContentBlockResourceView,
  richtext: ContentBlockRichTextView,
  separator: ContentBlockSeparatorView,
  image: ContentBlockImageView,
  heading: ContentBlockHeadingView,
  notification: ContentBlockNotificationView,
  pdf_viewer: ContentBlockPDFViewerView,
  url: ContentBlockURLView,
  training_item_url: ContentBlockTrainingItemURLView,
  giphy: ContentBlockEmbedView,
  calendly: ContentBlockEmbedSimpleView,
  typeform: ContentBlockEmbedSimpleView,
  youtube: ContentBlockEmbedView,
  youtube_live: ContentBlockEmbedView,
  vimeo: ContentBlockEmbedView,
  dailymotion: ContentBlockEmbedView,
  twitch: ContentBlockEmbedView,
  soundcloud: ContentBlockEmbedView,
  gdoc: ContentBlockEmbedView,
  gsheet: ContentBlockEmbedView,
  gform: ContentBlockHTMLView,
  gcalendar: ContentBlockEmbedView,
  gslide: ContentBlockEmbedView,
  html: ContentBlockHTMLView,
  genially: ContentBlockHTMLView,
  markdown: ContentBlockMarkdownView,
  toggle: ContentBlockToggleView,
  slideshare: ContentBlockOEmbedView,
  facebook: () => import('@shared/components/ContentBlocks/Views/Embedo.vue'),
  instagram: () => import('@shared/components/ContentBlocks/Views/Embedo.vue'),
  twitter: () => import('@shared/components/ContentBlocks/Views/Embedo.vue'),
  pinterest: () => import('@shared/components/ContentBlocks/Views/Embedo.vue'),
  figma: ContentBlockEmbedView,
  github: ContentBlockOEmbedView,
  codeblock: ContentBlockCodeBlockView,
  jsfiddle: ContentBlockEmbedView,
  codepen: ContentBlockEmbedView,
  codesandbox: ContentBlockEmbedView,
  chatgpt: ContentBlockEmbedView,
  tiktok: TiktokOEmbed,
};
