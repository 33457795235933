<script>
import _get from 'lodash/get';
import DOMPurify from 'dompurify';
import { getLocal, setLocal } from '@shared/services/Utils';
import utilsMixin from '@shared/mixins/utils';
import CommentsList from '@shared/components/CommentsList.vue';
import AppConfirmDialog from '@shared/components/AppConfirmDialog.vue';
import APICustomer from '@school/services/API/Customer';
import TrainingItemBlocks from '@school/components/TrainingItemBlocks.vue';
import CustomerTrainingItemQuiz from '@school/components/CustomerTrainingItemQuiz.vue';
import CustomerTrainingItemTasks from '@school/components/CustomerTrainingItemTasks.vue';
import CustomerTrainingItemAssignment from '@school/components/CustomerTrainingItemAssignment.vue';
import CustomerTrainingItemScorm from '@school/components/CustomerTrainingItemScorm.vue';
import CustomerTrainingItemNotes from '@school/components/CustomerTrainingItemNotes.vue';
import CustomerTrainingResources from '@school/components/CustomerTrainingResources.vue';
import TrainingItemFeedbackForm from '@school/components/TrainingItemFeedbackForm.vue';

const SESSION_UPDATE_DURATION = 120000;

export default {
  mixins: [utilsMixin],
  components: {
    TrainingItemBlocks,
    CustomerTrainingItemNotes,
    CustomerTrainingResources,
    CommentsList,
    TrainingItemFeedbackForm,
  },
  static: {
    APICustomer,
  },
  i18n: {
    messages: {
      fr: {
        COMPLETE_TRAINING_ITEM_ALERT_GENERIC: `
          Vous n'avez pas marqué cette leçon comme terminée,
          elle ne sera pas comptabilisée dans votre progression.
        `,
        COMPLETE_TRAINING_ITEM_ALERT_QUIZ: `
          Vous n'avez pas terminé ce quiz,
          il ne sera pas comptabilisé dans votre progression.
        `,
        COMPLETE_TRAINING_ITEM_ALERT_TASKS: `
          Vous n'avez pas terminé cette liste de tâches,
          elle ne sera pas comptabilisée dans votre progression.
        `,
        COMPLETE_TRAINING_ITEM_ALERT_ASSIGNMENT: `
          Ce devoir n'ayant pas encore été soumis et/ou corrigé,
          il ne sera pas comptabilisé dans votre progression.
        `,
        COMPLETE_TRAINING_ITEM_ALERT_SCORM: `
          Vous n'avez pas marqué cet élément comme terminé,
          il ne sera pas comptabilisé dans votre progression.
        `,
      },
    },
  },
  head() {
    const doesNotHaveTitle = (
      !this.currentTraining
      || !this.trainingItem
      || this.trainingItem.id !== Number(this.$route.params.id)
    );

    if (doesNotHaveTitle) {
      return {
        title: this.$t('loading'),
        titleTemplate: '',
      };
    }

    const { onglet } = this.$route.query;
    const trainingItemName = this.trainingItem.name;

    if (onglet) {
      setTimeout(() => this.$gtag.pageView(), 200);

      return {
        title: this.$t(`tabs.${onglet}`),
        titleTemplate: `%s - ${trainingItemName} - ${this.currentTraining.name}`,
      };
    }

    setTimeout(() => this.$gtag.pageView(), 200);

    return {
      title: trainingItemName,
      titleTemplate: `%s - ${this.currentTraining.name}`,
    };
  },
  data: () => ({
    isLoading: false,
    isUpdating: false,
    trainingItem: null,
    trainingItemSession: null,
    isInactive: false,
    hasTrackedTabQuit: false,
    hasTrackedInactivity: false,
  }),
  computed: {
    previewToken() {
      return this.$route.query.p;
    },
    store() {
      return this.$store.state.store.data;
    },
    authCustomer() {
      return this.previewToken ? null : this.$store.getters['auth/customer'];
    },
    currentTraining() {
      return this.$store.state.trainings.current;
    },
    canSeeFeedback() {
      return (
        this.trainingItem.type === 'GENERIC'
        || this.trainingItem.type === 'SCORM'
        || this.completedAt
      );
    },
    canSeeComments() {
      if (!this.currentTraining) {
        return null;
      }

      return (
        !this.store.feature_options.comments.disabled
        && ['ON', 'LOCKED'].includes(this.currentTraining.comments_status)
      );
    },
    currentTrainingItemComponent() {
      return {
        QUIZ: {
          component: CustomerTrainingItemQuiz,
          props: {
            trainingSlug: this.$route.params.slug,
            trainingItem: this.trainingItem,
            onQuizAnswered: this.onQuizAnswered,
          },
          events: {},
        },
        TASKS: {
          component: CustomerTrainingItemTasks,
          props: {
            trainingItem: this.trainingItem,
            progressionIsUpdating: this.isUpdating,
          },
          events: {
            'task-done': ({ tasks }) => {
              this.saveTasksState(tasks);
            },
            'task-undone': ({ tasks }) => {
              this.saveTasksState(tasks);
            },
            complete: ({ tasks }) => {
              if (this.completedAt) {
                this.saveTasksState(tasks);
                return;
              }

              const specificContent = { tasks };
              this.saveComplete(specificContent);
            },
          },
        },
        ASSIGNMENT: {
          component: CustomerTrainingItemAssignment,
          props: {
            trainingItem: this.trainingItem,
            progressionIsUpdating: this.isUpdating,
          },
        },
        SCORM: {
          component: CustomerTrainingItemScorm,
          props: {
            trainingItem: this.trainingItem,
          },
          events: {
            complete: () => {
              this.completeItem();
            },
          },
        },
      };
    },
    currentItemsFlatArray() {
      return this.$store.getters['trainings/currentItemsFlatArray'];
    },
    sanitizedDescription() {
      return DOMPurify.sanitize(this.trainingItem.description);
    },
    prevTrainingItem() {
      const index = this.currentItemsFlatArray.findIndex((val) => val.id === this.trainingItem.id);
      const prevItem = this.currentItemsFlatArray[index - 1];

      if (!prevItem) {
        return null;
      }

      return prevItem;
    },
    prevTrainingItemTo() {
      if (!this.prevTrainingItem) {
        return null;
      }

      return {
        name: 'mytrainingitem',
        params: { slug: this.$route.params.slug, id: this.prevTrainingItem.id },
        query: { p: this.previewToken },
      };
    },
    nextTrainingItem() {
      const index = this.currentItemsFlatArray.findIndex((val) => val.id === this.trainingItem.id);
      const nextItem = this.currentItemsFlatArray[index + 1];

      if (!nextItem) {
        return null;
      }

      return nextItem;
    },
    nextTrainingItemTo() {
      if (!this.nextTrainingItem) {
        return null;
      }

      return {
        name: 'mytrainingitem',
        params: { slug: this.$route.params.slug, id: this.nextTrainingItem.id },
        query: { p: this.previewToken },
      };
    },
    tokenQuery() {
      return this.previewToken ? { p: this.previewToken } : {};
    },
    completedAt() {
      return _get(this, 'trainingItem.progression.completed_at');
    },
    hasPageBlocked() {
      return (
        (['ASSIGNMENT'].includes(this.trainingItem.type) && this.sharedIsNotRecurringPlan)
        || (['TASKS'].includes(this.trainingItem.type) && this.sharedHasPercentPlan)
        || (['SCORM'].includes(this.trainingItem.type) && !this.sharedHasExpertPlan)
      );
    },
  },
  methods: {
    showNotCustomerAlert() {
      this.$buefy.dialog.alert(`
        Vous n'êtes pas connecté en tant qu'apprenant !
      `);
    },
    saveTasksState(tasks) {
      if (!this.authCustomer) {
        this.showNotCustomerAlert();
        return;
      }

      const data = { specific_content: { tasks } };
      this.isUpdating = true;
      this.updateProgression(data)
        .then(() => this.$showMessage.success())
        .finally(() => (this.isUpdating = false));
    },
    onItemCompleted(redirect = true) {
      let { nextTrainingItem } = this;

      if (nextTrainingItem) {
        nextTrainingItem = { ...nextTrainingItem };
        nextTrainingItem.blocked_by_previous = false;

        this.$store.commit('trainings/updateItemInCurrent', nextTrainingItem);

        if (nextTrainingItem.blocked_by_date || nextTrainingItem.blocked_by_delay) {
          return;
        }

        redirect && this.$router.push(this.nextTrainingItemTo);
      }
    },
    onQuizAnswered() {
      const { slug, id } = this.$route.params;

      return APICustomer.getTrainingItem(slug, id)
        .then((data) => {
          this.setItem(data);
          this.$store.commit('trainings/updateItemInCurrent', this.trainingItem);
          this.onItemCompleted(false);
        });
    },
    setItem(data) {
      data = data.data;
      data.content = data.content || [];
      this.trainingItem = data;

      if (this.authCustomer && !this.previewToken) {
        this.trackSession();
        this.handleInactivity();
        this.handleTabQuit();
      }
    },
    updateSession() {
      if (this.generatedSessionPromise) {
        return Promise.resolve();
      }

      return APICustomer.updateTrainingItemSession(
        this.$route.params.slug,
        this.$route.params.id,
        this.trainingItemSession.id,
        { end: this.$moment().utc().format('YYYY-MM-DD HH:mm:ss') },
      ).catch((error) => {
        if (error.response && [403, 404].includes(error.response.status)) {
          clearInterval(this.sessionInterval);
        }
        this.$errorHandlers.stack(error);
      });
    },
    finaliseSession(urlSlug, urlId) {
      if (this.trainingItemSession) {
        return APICustomer.updateTrainingItemSession(
          urlSlug, urlId, this.trainingItemSession.id,
          { end: this.$moment().utc().format('YYYY-MM-DD HH:mm:ss') },
        );
      }

      return Promise.resolve();
    },
    trackSession(shouldGenerateSession = false) {
      if (shouldGenerateSession && !this.generatedSessionPromise) {
        this.generatedSessionPromise = this.generateSession()
          .then(() => (this.generatedSessionPromise = null));
      }

      clearInterval(this.sessionInterval);
      this.sessionInterval = setInterval(this.updateSession, SESSION_UPDATE_DURATION);
    },
    handleTabQuit() {
      if (this.hasTrackedTabQuit) {
        return;
      }

      this.hasTrackedTabQuit = true;
      const customerSession = this.store.feature_options.customer_session;

      if (this.$store.getters['store/isPercentPlan'] || customerSession.disabled_on_tab_quit === false) {
        return;
      }

      const onVisibilityChange = () => {
        if (this.isInactive) {
          return;
        }

        if (document.hidden) {
          clearInterval(this.sessionInterval);
        } else {
          this.trackSession(true);
        }
      };

      document.addEventListener('visibilitychange', onVisibilityChange);

      this.$on('hook:destroyed', () => {
        document.removeEventListener('visibilitychange', onVisibilityChange);
      });
    },
    handleInactivity() {
      if (this.hasTrackedInactivity) {
        return;
      }

      this.hasTrackedInactivity = true;
      const customerSession = this.store.feature_options.customer_session;

      if (this.$store.getters['store/isPercentPlan'] || customerSession.inactivity_timeout <= 0) {
        return;
      }

      const trackInactivity = () => {
        if (this.isInactive) {
          return;
        }

        const timeout = customerSession.inactivity_timeout * 60 * 1000;

        clearTimeout(this.inactivityTimeout);
        this.inactivityTimeout = setTimeout(() => {
          clearInterval(this.sessionInterval);

          this.isInactive = true;
          this.$buefy.dialog.alert({
            message: 'Êtes-vous toujours là ?',
            confirmText: 'Oui !',
            onConfirm: () => {
              this.isInactive = false;

              this.trackSession(true);
              trackInactivity();
            },
          });
        }, timeout);
      };

      window.addEventListener('mousemove', trackInactivity);
      window.addEventListener('mousedown', trackInactivity);
      window.addEventListener('mouseup', trackInactivity);
      window.addEventListener('scroll', trackInactivity);

      this.$on('hook:destroyed', () => {
        clearTimeout(this.inactivityTimeout);
        window.removeEventListener('mousemove', trackInactivity);
        window.removeEventListener('mousedown', trackInactivity);
        window.removeEventListener('mouseup', trackInactivity);
        window.removeEventListener('scroll', trackInactivity);
      });

      trackInactivity();
    },
    generateSession() {
      const { slug, id } = this.$route.params;

      return APICustomer.startTrainingItemSession(slug, id)
        .then((data) => (this.trainingItemSession = data.data));
    },
    loadTrainingItem() {
      clearInterval(this.sessionInterval);

      const { slug, id } = this.$route.params;
      const token = this.previewToken;
      this.isLoading = true;

      let promise = this.$store.dispatch('trainings/find', { slug, token });

      if (this.authCustomer && !this.previewToken) {
        promise = promise.then(this.generateSession);
      }

      promise
        .then(() => APICustomer.getTrainingItem(slug, id, token))
        .then(this.setItem)
        .catch((error) => {
          const status = error.response && error.response.status;

          if (error.isAxiosError && !status) {
            this.$errorHandlers.showNetworkErrorDialog();
            return;
          }

          if (status === 404) {
            this.$store.commit('errors/setError', {
              code: 404,
              message: `
                Cette leçon n'existe pas ou plus sur <strong>${this.store.name}</strong>
              `,
            });

            return;
          }

          if (status === 403) {
            this.$store.commit('errors/setError', {
              code: 403,
              title: error.response.data.message,
            });

            return;
          }

          this.$errorHandlers.axios(error);
        })
        .finally(() => (this.isLoading = false));
    },
    setProgression(data) {
      this.trainingItem.progression = data;
    },
    updateProgression(data) {
      return APICustomer
        .updateTrainingItemProgression(this.$route.params.slug, this.trainingItem.id, data)
        .then((data) => {
          this.setProgression(data.data);
          this.$store.commit('trainings/updateItemInCurrent', this.trainingItem);
        });
    },
    completeItem(specificContent) {
      if (!this.authCustomer) {
        this.showNotCustomerAlert();
        return;
      }

      this.confirmCompleteItem(specificContent);
    },
    confirmCompleteItem(specificContent) {
      const {
        LSKEY_TRAINING_ITEM_CONFIRM_COUNT,
        LSKEY_TRAINING_ITEM_HIDE_CONFIRM,
      } = this.$constants;

      if (getLocal(LSKEY_TRAINING_ITEM_HIDE_CONFIRM)) {
        this.saveComplete(specificContent);
        return;
      }

      const i = getLocal(LSKEY_TRAINING_ITEM_CONFIRM_COUNT, true, 0) + 1;
      setLocal(LSKEY_TRAINING_ITEM_CONFIRM_COUNT, Math.min(i, 3));

      const modal = this.$buefy.modal.open({
        parent: this,
        component: AppConfirmDialog,
        hasModalCard: true,
        width: '460px',
        canCancel: ['escape', 'outside'],
        props: {
          type: 'is-danger',
          message: 'Elle sera marquée comme terminée, mais vous pourrez toujours la consulter.',
          showHideOption: i >= 3,
        },
        events: {
          cancel: () => modal.close(),
          confirm: (hideNextTime) => {
            if (hideNextTime) {
              setLocal(LSKEY_TRAINING_ITEM_HIDE_CONFIRM, 1);
            }

            this.saveComplete(specificContent);
            modal.close();
          },
        },
      });
    },
    saveComplete(specificContent) {
      if (!this.authCustomer) {
        this.showNotCustomerAlert();
        return;
      }

      const { slug, id } = this.$route.params;
      const data = {
        is_completed: true,
        specific_content: specificContent,
      };

      this.isUpdating = true;

      this.finaliseSession(slug, id)
        .then(() => this.updateProgression(data))
        .then(() => this.onItemCompleted())
        .finally(() => (this.isUpdating = false));
    },
    onFeedbackDone(feedback) {
      if (!this.authCustomer) {
        this.showNotCustomerAlert();
        return;
      }

      const specificContent = _get(this, 'trainingItem.progression.specific_content') || {};

      specificContent.feedback = feedback;

      const data = { specific_content: specificContent };
      this.isUpdating = true;
      this.updateProgression(data)
        .then(() => this.$showMessage.success())
        .finally(() => (this.isUpdating = false));
    },
    handleLeave(to, from, next) {
      if (to.path === from.path) {
        next();
        return;
      }

      if (!this.previewToken && !this.completedAt) {
        this.$buefy.dialog.confirm({
          message: this.$t(`COMPLETE_TRAINING_ITEM_ALERT_${this.trainingItem.type}`),
          confirmText: 'Quitter',
          cancelText: 'Rester',
          focusOn: 'cancel',
          onConfirm: () => {
            this.finaliseSession(from.params.slug, from.params.id);
            next();
          },
          onCancel: () => next(false),
        });
      } else {
        this.finaliseSession(from.params.slug, from.params.id);
        next();
      }
    },
    onNavClick(item) {
      if (!this.previewToken && (!item.progression || !item.progression.completed_at)) {
        if (item.blocked_by_previous) {
          const key = this.trainingItem.type.toLowerCase();
          const message = this.$t(`messages.blocked_by_previous_training_item_${key}`);
          message && this.$buefy.dialog.alert(message);
        }
      }
    },
  },
  watch: {
    $route(to, from) {
      if (to.path === from.path || to.name !== 'mytrainingitem') {
        return;
      }

      this.loadTrainingItem();
    },
  },
  created() {
    this.loadTrainingItem();
  },
  beforeRouteLeave(to, from, next) {
    this.handleLeave(to, from, next);
  },
  beforeRouteUpdate(to, from, next) {
    this.handleLeave(to, from, next);
  },
  beforeDestroy() {
    clearInterval(this.sessionInterval);
  },
  destroyed() {
    clearInterval(this.sessionInterval);
    setTimeout(() => clearInterval(this.sessionInterval), 500);
  },
};
</script>

<template>
  <div class="mticomp has-background-white">
    <nav class="mticomp_tabs tabs has-scroll-shadow is-centered has-background-white">
      <ul>
        <router-link tag="li" :to="{query: {...tokenQuery}}" exact-active-class="is-active">
          <a>
            <b-skeleton v-if="isLoading" width="100" height="32" />
            <template v-else>
              Contenu
            </template>
          </a>
        </router-link>
        <router-link tag="li" :to="{query: {onglet: 'mes-notes', ...tokenQuery}}" exact-active-class="is-active">
          <a>
            <b-skeleton v-if="isLoading" width="100" height="32" />
            <template v-else>
              Prise de note
            </template>
          </a>
        </router-link>
        <router-link tag="li" :to="{query: {onglet: 'annexes', ...tokenQuery}}" exact-active-class="is-active">
          <a>
            <b-skeleton v-if="isLoading" width="100" height="32" />
            <template v-else>
              Annexes ({{ trainingItem.resources.length }})
            </template>
          </a>
        </router-link>
        <router-link
          v-if="canSeeComments"
          tag="li"
          :to="{query: {onglet: 'questions', ...tokenQuery}}"
          exact-active-class="is-active"
        >
          <a>
            <b-skeleton v-if="isLoading" width="100" height="32" />
            <template v-else>
              Commentaires ({{ trainingItem.comments_count }})
            </template>
          </a>
        </router-link>
      </ul>
    </nav>

    <section class="mticomp_main section is-medium pt-5">
      <div v-if="isLoading" class="container is-728">
        <b-skeleton height="48" width="60%" position="is-centered" />
        <b-skeleton height="200" />
        <b-skeleton height="200" />
        <b-skeleton height="200" />
      </div>
      <section
        v-else-if="hasPageBlocked"
        class="box py-20 is-primary is-custom content has-text-centered"
      >
        <h2 class="title is-4 is-size-5-touch is-custom">
          Cette page est bloquée
        </h2>

        <p class="subtitle">
          Veuillez contacter votre formateur pour la débloquer.
        </p>
      </section>
      <template v-else>
        <div v-show="!$route.query.onglet">
          <div class="container is-728 mb-6">
            <h1 class="title is-custom is-size-4-touch has-text-centered">
              {{ trainingItem.name }}
            </h1>
            <teleport-target name="training_item_under_title" multiple />
            <div
              v-if="trainingItem.description"
              class="content"
              v-html="sanitizedDescription"
            />
          </div>

          <TrainingItemBlocks
            v-if="trainingItem.type == 'GENERIC'"
            class="mticomp_content"
            :trainingItem="trainingItem"
          />
          <div v-else :class="trainingItem.type == 'SCORM' ? '' : 'container is-728'">
            <component
              :is="currentTrainingItemComponent[trainingItem.type].component"
              v-bind="currentTrainingItemComponent[trainingItem.type].props"
              v-on="currentTrainingItemComponent[trainingItem.type].events"
            />
          </div>
        </div>

        <div class="container is-728">
          <CustomerTrainingItemNotes
            v-if="$route.query.onglet == 'mes-notes'"
            :training-slug="$route.params.slug"
            :training-item="trainingItem"
            @update="setProgression"
          />
          <CustomerTrainingResources
            v-if="$route.query.onglet == 'annexes'"
            :count="trainingItem.resources.length"
            :training-item="trainingItem"
          />
          <CommentsList
            v-else-if="$route.query.onglet == 'questions'"
            :api="$options.static.APICustomer"
            :store="store"
            :author="authCustomer"
            :training="currentTraining"
            :training-identifier="$route.params.slug"
            :training-item="trainingItem"
          />
        </div>

        <TrainingItemFeedbackForm
          v-if="!isLoading && canSeeFeedback"
          class="mt-20"
          :training="currentTraining"
          :trainingItem="trainingItem"
          :isUpdating="isUpdating"
          @done="onFeedbackDone"
        />

        <hr>

        <footer class="py-10">
          <p class="has-text-centered">
            <b-button
              v-if="(!authCustomer || !completedAt)&& (trainingItem.type == 'GENERIC' || trainingItem.type == 'SCORM')"
              type="is-primary is-custom"
              size="is-medium"
              icon-pack="far"
              icon-left="square"
              :loading="isUpdating"
              @click="completeItem()"
            >
              J'ai terminé cette leçon
            </b-button>
            <span
              v-if="trainingItem.progression && completedAt"
              class="tag is-large is-primary is-custom"
            >
              <b-icon icon="check-square" />
              <span>
                <template v-if="trainingItem.type == 'QUIZ'">
                  Quiz complété le
                </template>
                <template v-else-if="trainingItem.type == 'ASSIGNMENT'">
                  Devoir finalisé le
                </template>
                <template v-else>
                  Leçon terminée le
                </template>
                {{ completedAt | momentFromUTC | moment('DD/MM/YY') }}
              </span>
            </span>
          </p>
          <nav class="level is-mobile mt-10">
            <div class="level-left">
              <b-button
                v-if="prevTrainingItem"
                class="is-custom tdecoration-underline"
                type="is-text"
                tag="router-link"
                :event="!previewToken && prevTrainingItem.blocked_by_previous ? '' : 'click'"
                :to="prevTrainingItemTo"
                @click.native="onNavClick(prevTrainingItem)"
              >
                Précédent
              </b-button>
            </div>
            <div class="level-right">
              <b-button
                v-if="nextTrainingItem"
                class="is-custom tdecoration-underline"
                type="is-text"
                tag="router-link"
                :event="!previewToken && nextTrainingItem.blocked_by_previous ? '' : 'click'"
                :to="nextTrainingItemTo"
                @click.native="onNavClick(nextTrainingItem)"
              >
                Suivant
              </b-button>
            </div>
          </nav>
          <teleport to="trainingItemNav">
            <b-tooltip position="is-left" label="Précédent">
              <b-button
                v-if="prevTrainingItem"
                icon-left="chevron-left"
                tag="router-link"
                :event="!previewToken && prevTrainingItem.blocked_by_previous ? '' : 'click'"
                :to="prevTrainingItemTo"
                @click.native="onNavClick(prevTrainingItem)"
              />
            </b-tooltip>
            <b-tooltip position="is-left" label="Suivant">
              <b-button
                v-if="nextTrainingItem"
                icon-left="chevron-right"
                tag="router-link"
                :event="!previewToken && nextTrainingItem.blocked_by_previous ? '' : 'click'"
                :to="nextTrainingItemTo"
                @click.native="onNavClick(nextTrainingItem)"
              />
            </b-tooltip>
          </teleport>
        </footer>
      </template>
    </section>
  </div>
</template>

<style lang="scss" scoped>
.mticomp {
  min-height: calc(100vh - #{$navbar-height} - #{$watermark-height});

  &_tabs {
    position: sticky;
    z-index: 10;
    top: $navbar-height;
    left: $sidenav;
    right: 0;

    @include touch {
      left: 0;
    }

    @include widescreen {
      left: $sidenav-large;
    }
  }

  &_main {
    @include mobile {
      padding-left: pxToRem(10);
      padding-right: pxToRem(10);
    }
  }
}
</style>
