<script>
import VueSimpleEmbed from '@shared/components/VueSimpleEmbed.vue';

export default {
  components: { VueSimpleEmbed },
  props: {
    question: {
      type: Object,
      required: true,
    },
  },
};
</script>

<template>
  <div v-if="question.content.length > 0">
    <slot name="delete" />

    <figure
      v-if="question.content[0].type === 'image'"
      key="image"
      class="quiz-question"
    >
      <img :src="question.content[0].data.url" :alt="question.question">
    </figure>
    <figure
      v-else-if="question.content[0].type === 'video'"
      key="video"
      class="quiz-question"
    >
      <vue-plyr v-if="question.content[0].data.type != 'embed'">
        <video :src="question.content[0].data.url" />
      </vue-plyr>
      <VueSimpleEmbed
        v-else
        :url="question.content[0].data.url"
        parser="plyr"
      />
    </figure>
    <figure
      v-else-if="question.content[0].type === 'audio'"
      key="audio"
      class="quiz-question"
    >
      <vue-plyr v-if="question.content[0].data.type != 'embed'">
        <audio :src="question.content[0].data.url" />
      </vue-plyr>
      <VueSimpleEmbed
        v-else
        :url="question.content[0].data.url"
      />
    </figure>
  </div>
</template>
