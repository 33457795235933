<script>
import anchorme from 'anchorme';
import DOMPurify from 'dompurify';
import { htmlEntities } from '@shared/services/Utils';

export default {
  props: {
    comment: {
      type: String,
      required: true,
    },
  },
  computed: {
    finalComment() {
      return DOMPurify.sanitize(
        anchorme(htmlEntities(this.comment)),
        { ALLOWED_TAGS: ['a'] },
      );
    },
  },
};
</script>

<template>
  <p class="box content wspace-preline" v-html="finalComment" />
</template>
