<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    resource: {
      type: Object,
      required: true,
    },
  },
  computed: {
    tag() {
      return (this.item.blocked_by_date || this.item.blocked_by_delay) ? 'div' : 'a';
    },
    href() {
      if (this.item.blocked_by_date || this.item.blocked_by_delay) {
        return false;
      }

      if (this.resource.type === 'LINK' || this.resource.path.startsWith('http')) {
        return this.resource.path;
      }

      return `${this.$env.apiResourcesURL}/${this.resource.path}`;
    },
  },
};
</script>

<template>
  <component :is="tag" :href="href" target="_blank" rel="noopener noreferrer">
    <span v-if="item.blocked_by_date" class="has-text-black">
      <b-icon class="mr-1" icon="lock" />
      (accessible le {{ item.blocked_by_date | momentFromUTC | moment('DD/MM/YY [à] HH:mm') }})
    </span>
    <span v-else-if="item.blocked_by_delay" class="has-text-black">
      <b-icon class="mr-1" icon="lock" />
      (accessible le {{ item.blocked_by_delay | momentFromUTC | moment('DD/MM/YY [à] HH:mm') }})
    </span>
    <template v-else>
      {{ resource.name }}
    </template>
  </component>
</template>
