<script>
import anchorme from 'anchorme';
import DOMPurify from 'dompurify';
import { htmlEntities } from '@shared/services/Utils';
import TextareaAutoGrowVue from '@shared/components/TextareaAutoGrow.vue';

const textToUrl = (text) => DOMPurify.sanitize(
  anchorme(htmlEntities(text)),
  { ALLOWED_TAGS: ['a'] },
);

export default {
  components: { TextareaAutoGrowVue },
  props: {
    context: {
      type: String,
      required: true,
      validator(value) {
        return ['USER', 'CUSTOMER'].includes(value);
      },
    },
    value: {
      type: String,
      required: true,
    },
    isSending: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    submission: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    newComment: '',
  }),
  computed: {
    reverseDiscussion() {
      return [...this.submission.discussions]
        .sort((a, b) => (
          (new Date(a.created_at)) - (new Date(b.created_at))
        ))
        .map((message) => ({
          ...message,
          comment: textToUrl(message.comment),
        }));
    },
  },
  watch: {
    submission(val, oldVal) {
      if (oldVal && oldVal.discussions.length !== val.discussions.length) {
        this.$nextTick(() => (
          this.$refs.list.scroll(0, this.$refs.list.scrollHeight)
        ));
      }
    },
  },
};
</script>

<template>
  <section class="compad">
    <h2 class="title is-4 is-size-5-touch is-custom">
      Discussion privée
    </h2>

    <div
      v-if="submission.discussions.length"
      ref="list"
      class="compad_list box p-1-touch is-flex is-flex-direction-column mb-5"
    >
      <article
        v-for="one in reverseDiscussion"
        :key="one.created_at"
        class="mb-2 p-3 bradius-4"
      >
        <h3 class="title is-7 is-size-8-touch mb-1">
          <template v-if="one.creator_type != context">
            {{ one.creator.firstname }}
            {{ one.creator_type === 'CUSTOMER' ? one.creator.lastname : '' }}
          </template>
          <template v-else>
            Vous
          </template>

          <span class="is-size-8 is-size-9-touch has-text-weight-normal">
            • le {{ one.created_at | momentFromUTC | moment('DD/MM/YY [à] HH:mm') }}
          </span>
        </h3>
        <p class="content wspace-preline is-size-8" v-html="one.comment" />
      </article>
    </div>
    <p
      v-if="!submission.discussions.length"
      ref="list"
      class="box py-3"
    >
      Aucun message.
    </p>

    <p class="box" v-if="disabled">
      La discussion privée a été désactivé.
      <br>
      Vous pouvez toujours consultez les
      anciens messages.
    </p>
    <form v-else @submit.prevent="!isSending && $emit('send')">
      <div class="field">
        <TextareaAutoGrowVue>
          <b-input
            type="textarea"
            rows="3"
            placeholder="Votre message privé"
            expanded
            maxlength="1000"
            required
            :value="value"
            @input="$emit('input', $event)"
          />
        </TextareaAutoGrowVue>
      </div>
      <div class="field has-text-right">
        <b-button
          type="is-primary"
          class="is-custom"
          native-type="submit"
          :loading="isSending"
        >
          Envoyer un message
        </b-button>
      </div>
    </form>
  </section>
</template>

<style lang="scss" scoped>
.compad {
  &_list {
    overflow: auto;
    max-height: 460px;
    padding: pxToRem(12px);
  }
}
</style>
