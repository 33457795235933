<script>
import shuffle from 'lodash/shuffle';
import { required } from 'vuelidate/lib/validators';
import responsiveMixin from '@shared/mixins/responsive';
import ListQuizResponse from '@shared/components/ListQuizResponse.vue';
import QuizQuestionMediaContent from '@shared/components/QuizQuestionMediaContent.vue';
import APICustomer from '@school/services/API/Customer';
import CustomerTrainingItemQuizAnswer from '@school/components/CustomerTrainingItemQuizAnswer.vue';

export default {
  mixins: [responsiveMixin({ isMobileScreen: 768 })],
  components: {
    ListQuizResponse,
    QuizQuestionMediaContent,
    CustomerTrainingItemQuizAnswer,
  },
  props: {
    trainingSlug: {
      type: String,
      required: true,
    },
    trainingItem: {
      type: Object,
      required: true,
    },
    onQuizAnswered: {
      type: Function,
      default: () => (() => {}),
    },
  },
  data() {
    return {
      isLoading: false,
      isSending: false,
      quizIsOpen: false,
      hasAnswered: false,
      userAnswers: [],
      quizResponse: {},
      currentQuizDetailsPage: 1,
    };
  },
  computed: {
    authCustomer() {
      return this.$store.getters['auth/customer'];
    },
    completedAt() {
      return this.trainingItem.progression && this.trainingItem.progression.completed_at;
    },
    perPage() {
      const perPage = this.trainingItem.specific_content.questions_per_page;
      return perPage <= 0 ? this.trainingItem.quiz_details.length : perPage;
    },
    totalPages() {
      return this.perPage <= 0 ? 1 : Math.ceil(this.trainingItem.quiz_details.length / this.perPage);
    },
    currentQuizDetails() {
      const { quiz_details } = this.trainingItem;

      if (this.totalPages <= 1) {
        return quiz_details;
      }

      return quiz_details.slice(this.questionIndexStep, this.questionIndexStep + this.perPage);
    },
    questionIndexStep() {
      if (this.totalPages > 1) {
        return this.perPage * (this.currentQuizDetailsPage - 1);
      }

      return 0;
    },
    hasPrevPage() {
      return this.totalPages > 1 && this.currentQuizDetailsPage > 1;
    },
    hasNextPage() {
      return this.totalPages > 1 && this.currentQuizDetailsPage < this.totalPages;
    },
    isLastPage() {
      return this.currentQuizDetailsPage === this.totalPages;
    },
  },
  validations() {
    if (this.completedAt) {
      return {};
    }

    return {
      userAnswers: {
        $each: {
          answers: { required },
        },
      },
    };
  },
  watch: {
    'trainingItem.quiz_details': {
      immediate: true,
      handler(val) {
        this.userAnswers = val.map((q) => ({ question_id: q.id, answers: [] }));
      },
    },
  },
  created() {
    this.loadQuizResponse();
  },
  methods: {
    loadQuizResponse() {
      if (!this.authCustomer || !this.trainingItem.quiz_submissions_count) {
        return;
      }

      this.isLoading = true;
      APICustomer.getQuizResponse(this.trainingSlug, this.trainingItem.id)
        .then(this.setQuizResponse)
        .finally(() => (this.isLoading = false));
    },
    setQuizResponse({ data }) {
      if (!data) {
        return;
      }

      this.hasAnswered = true;
      this.quizResponse = data;
    },
    startQuiz() {
      const { specific_content } = this.trainingItem;

      if (specific_content.shuffle) {
        this.trainingItem.quiz_details = shuffle(this.trainingItem.quiz_details);
      }

      this.quizIsOpen = true;
    },
    closeQuiz() {
      this.quizIsOpen = false;
      this.currentQuizDetailsPage = 1;
      this.$v.$reset();
    },
    currentQuizDetailsHasError() {
      return this.currentQuizDetails.some((q, qk) => {
        const $v = this.$v.userAnswers.$each[qk + this.questionIndexStep].answers;
        return !this.dataIsValid(this.isSending, $v);
      });
    },
    prevPage() {
      if (this.currentQuizDetailsHasError()) {
        this.$nextTick(() => this.$scrollTo('.has-error', { container: this.$refs.body }));
        return;
      }

      if (this.currentQuizDetailsPage > 1) {
        this.currentQuizDetailsPage--;
      } else {
        this.currentQuizDetailsPage = 1;
      }

      this.$refs.body.scroll(0, 0);
    },
    nextPage() {
      if (this.currentQuizDetailsHasError()) {
        this.$nextTick(() => this.$scrollTo('.has-error', { container: this.$refs.body }));
        return;
      }

      if (this.currentQuizDetailsPage < this.totalPages) {
        this.currentQuizDetailsPage++;
      } else {
        this.currentQuizDetailsPage = this.totalPages;
      }

      this.$refs.body.scroll(0, 0);
    },
    handle() {
      if (!this.authCustomer) {
        this.$buefy.dialog.alert(`
          Vous n'êtes pas connecté en tant qu'apprenant !
        `);
        return;
      }

      this.$buefy.dialog.confirm({
        message: 'Attention ! <br> Vos réponses seront définitives.',
        focusOn: 'cancel',
        onConfirm: () => {
          const data = [...this.userAnswers];
          this.isSending = true;
          APICustomer
            .answerQuiz(this.trainingSlug, this.trainingItem.id, data)
            .then((data) => {
              this.setQuizResponse(data);
              this.closeQuiz();
              return this.onQuizAnswered();
            })
            .finally(() => (this.isSending = false));
        },
      });
    },
  },
};
</script>

<template>
  <div class="ctiqcomp">
    <b-skeleton v-if="isLoading" height="400" />
    <template v-else>
      <div v-if="hasAnswered">
        <p class="mb-2 has-text-centered">
          Votre taux de réussite :<br>
          <strong class="is-size-1">
            {{ quizResponse.percentage_successful_questions }}%
          </strong>
        </p>
        <p v-if="trainingItem.specific_content.min_success_rate" class="help has-text-centered mb-3">
          Le taux de réussite minimum pour valider ce quiz :
          {{ trainingItem.specific_content.min_success_rate }}%
        </p>
        <p v-if="!completedAt" class="mb-10 has-text-centered">
          <b-button v-show="!isSending" type="is-primary is-custom" @click="startQuiz">
            Recommencer le quiz
          </b-button>
        </p>
        <ListQuizResponse
          v-if="completedAt && trainingItem.specific_content.hide_answers != true"
          :trainingItem="trainingItem"
          :quizResponse="quizResponse"
        />
      </div>
      <p v-else class="has-text-centered">
        <b-button type="is-primary is-custom" @click="startQuiz">
          Commencer le quiz
        </b-button>
      </p>

      <b-modal
        v-if="!completedAt"
        :active.sync="quizIsOpen"
        :can-cancel="false"
        :full-screen="isMobileScreen"
        has-modal-card
      >
        <section class="modal-card">
          <header class="modal-card-head py-3">
            <h1 class="modal-card-title flex-auto has-text-clipped">
              {{ trainingItem.name }}
            </h1>
            <b-button
              class="flex-none h-auto p-0 ml-5"
              type="is-text"
              icon-left="times"
              aria-label="close"
              @click="closeQuiz"
            />
          </header>
          <form ref="body" class="modal-card-body" @submit.prevent="dataIsValid(isSending) && handle()">
            <div class="mw-640 mx-auto">
              <article
                v-for="(q, qk) in currentQuizDetails"
                :key="q.id"
                :class="{'mt-5': qk != 0}"
              >
                <h3 class="title is-5">
                  {{ q.question }}
                </h3>

                <QuizQuestionMediaContent
                  v-if="!$store.getters['store/hasPercentPlan']"
                  :question="q"
                />

                <hr>

                <div class="has-background-light is-custom py-5 px-3 bradius-4">
                  <b-field v-for="a in q.answers" :key="a.id">
                    <CustomerTrainingItemQuizAnswer
                      :question="q"
                      :answer="a"
                      :userAnswer="userAnswers[qk + questionIndexStep]"
                    />
                  </b-field>
                </div>

                <b-field
                  v-if="$v.userAnswers.$each[qk + questionIndexStep].$error"
                  :class="{'has-error': $v.userAnswers.$each[qk + questionIndexStep].$error}"
                  v-bind="$getErrorProps($v.userAnswers.$each[qk + questionIndexStep].answers, ['atLeastOneAnswer'])"
                />
              </article>
              <div v-show="isLastPage" class="mt-5 buttons is-centered">
                <b-button
                  native-type="submit"
                  type="is-primary is-custom"
                  :loading="isSending"
                >
                  Envoyer mes réponses
                </b-button>
              </div>
            </div>
          </form>
          <footer
            class="modal-card-foot is-justify-content-flex-end"
            :class="{'has-background-light': totalPages > 1}">
            <template v-if="totalPages > 1">
              <b-button icon-left="chevron-left" :disabled="!hasPrevPage" @click="prevPage">
                Précédent
              </b-button>
              <b-button icon-left="chevron-right" :disabled="!hasNextPage" @click="nextPage">
                Suivant
              </b-button>
            </template>
          </footer>
        </section>
      </b-modal>
    </template>
  </div>
</template>
