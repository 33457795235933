<script>
import QuizAnswerMediaContent from '@shared/components/QuizAnswerMediaContent.vue';

export default {
  components: { QuizAnswerMediaContent },
  props: {
    question: {
      type: Object,
      required: true,
    },
    answer: {
      type: Object,
      required: true,
    },
    userAnswer: {
      type: Object,
      required: true,
    },
  },
  computed: {
    field() {
      return this.question.feature_options.type === 'single'
        ? 'b-radio' : 'b-checkbox';
    },
    name() {
      return this.question.feature_options.type === 'single'
        ? `ans-${this.question.id}`
        : `ans--${this.question.id}-${this.answer.id}`;
    },
    nativeValue() {
      return this.question.feature_options.type === 'single'
        ? [this.answer.id] : this.answer.id;
    },
    isSelected() {
      return this.userAnswer.answers.includes(this.answer.id);
    },
  },
};
</script>

<template>
  <div class="box p-3" :class="{'is-primary is-custom is-small': isSelected}">
    <component
      :is="field"
      class="w-full is-flex"
      type="is-custom"
      :name="name"
      :native-value="nativeValue"
      v-model="userAnswer.answers"
    >
      {{ answer.answer }}
    </component>
    <QuizAnswerMediaContent
      v-if="!$store.getters['store/hasPercentPlan']"
      class="mt-3"
      :answer="answer"
    />
  </div>
</template>
