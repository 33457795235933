<script>
import _get from 'lodash/get';
import DOMPurify from 'dompurify';

export default {
  props: {
    trainingItem: {
      type: Object,
      required: true,
    },
    progressionIsUpdating: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      isSending: false,
      tasksDone: {},
    };
  },
  computed: {
    authCustomer() {
      return this.$store.getters['auth/customer'];
    },
    completedAt() {
      return this.trainingItem.progression && this.trainingItem.progression.completed_at;
    },
    tasks() {
      return this.trainingItem
        .specific_content
        .tasks
        .map((task) => ({ ...task, desicription: DOMPurify.sanitize(task.description) }));
    },
    nbTasksDone() {
      return Object.keys(this.tasksDone).length;
    },
    nbTasksTodo() {
      return this.trainingItem.specific_content.tasks.length;
    },
  },
  methods: {
    onTaskChange(task) {
      if (this.tasksDone[task.id]) {
        this.$buefy.dialog.confirm({
          message: 'Êtes-vous sûr(e) de vouloir décocher cette tâche ?',
          confirmText: 'Oui',
          cancelText: 'Non',
          focusOn: 'cancel',
          onConfirm: () => {
            delete this.tasksDone[task.id];
            this.tasksDone = { ...this.tasksDone };
            this.$emit('task-undone', { task, tasks: this.tasksDone });
          },
          onCancel: () => {
            const currentTasks = { ...this.tasksDone };
            delete this.tasksDone[task.id];
            this.tasksDone = { ...this.tasksDone };
            this.$nextTick(() => (this.tasksDone = currentTasks));
          },
        });

        return;
      }

      this.tasksDone[task.id] = {
        id: task.id,
        done_at: this.$moment().utc().format('YYYY-MM-DD HH:mm:ss'),
      };
      this.tasksDone = { ...this.tasksDone };

      if (this.nbTasksDone === this.nbTasksTodo) {
        this.confirmLastTask(task);
        return;
      }

      this.$emit('task-done', { task, tasks: this.tasksDone });
    },
    confirmLastTask(task) {
      if (this.completedAt) {
        this.$emit('complete', { task, tasks: this.tasksDone });
        return;
      }

      this.$buefy.dialog.confirm({
        title: 'Avez-vous terminé toutes ces tâches ?',
        message: 'En confirmant, cette leçon sera marquée comme terminée, mais vous pourrez toujours la consulter.',
        confirmText: 'Oui',
        cancelText: 'Non',
        focusOn: 'cancel',
        onConfirm: () => {
          this.$emit('complete', { task, tasks: this.tasksDone });
        },
        onCancel: () => {
          delete this.tasksDone[task.id];
          this.tasksDone = { ...this.tasksDone };
        },
      });
    },
  },
  created() {
    this.tasksDone = _get(this, 'trainingItem.progression.specific_content.tasks', {});
  },
};
</script>

<template>
  <div class="ctitcomp">
    <b-skeleton v-if="isLoading" height="400" />
    <template v-else>
      <form class="is-relative" @submit.prevent>
        <b-loading :is-full-page="false" v-model="progressionIsUpdating" />

        <h3 class="title is-5 has-text-centered">
          Tâches
          <span class="has-text-weight-normal is-size-6">({{ nbTasksDone }} / {{ nbTasksTodo }})</span>
        </h3>
        <ul>
          <li
            v-for="task in tasks"
            :key="task.id"
            class="box content">
            <b-checkbox
              class="w-full is-align-items-flex-start title is-size-6 mb-0"
              size="is-medium"
              :value="!!tasksDone[task.id]"
              @input="onTaskChange(task)">
                {{ task.name }}
            </b-checkbox>
            <p v-if="tasksDone[task.id]" class="mt-1 ml-9 has-text-primary">
              (faite le {{ tasksDone[task.id].done_at | momentFromUTC | moment('DD/MM/YY [à] HH:mm') }})
            </p>
            <p v-if="task.description" class="mt-1 ml-9 wspace-preline" v-html="task.description" />
          </li>
        </ul>
      </form>
    </template>
  </div>
</template>
