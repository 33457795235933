<script>
export default {
  props: {
    type: {
      type: String,
      default: 'is-primary',
    },
    title: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    messageHTML: {
      type: String,
      default: '',
    },
    cancelText: {
      type: String,
      default: '',
    },
    confirmText: {
      type: String,
      default: '',
    },
    showHideOption: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({ hideNextTime: false }),
};
</script>

<template>
  <AppCardModal>
    <template v-if="title" #title>
      {{ title }}
    </template>
    <template #body>
      <template v-if="message">
        {{ message }}
      </template>
      <div v-if="messageHTML" v-html="messageHTML" />
      <div v-if="showHideOption" class="mt-5">
        <b-checkbox v-model="hideNextTime">
          Ne plus afficher cette confirmation
        </b-checkbox>
      </div>
    </template>
    <template #foot>
      <b-button @click="$emit('cancel')">
      {{ cancelText || 'Annuler' }}
      </b-button>
      <b-button
        :type="type"
        @click="$emit('confirm', hideNextTime)"
      >
        {{ confirmText || 'Confirmer' }}
      </b-button>
    </template>
  </AppCardModal>
</template>
