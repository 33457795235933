<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    options: {
      type: Object,
      required: true,
    },
  },
};
</script>

<template>
  <div class="bffcomp">
    <b-tooltip
      v-for="(rate, id) in options"
      :key="id"
      :label="rate.text"
    >
      <b-button
        class="feedback-btn h-auto py-0 px-3 is-size-4 has-anime-scale"
        :class="{'is-active': value == id}"
        type="is-text"
        :icon-left="rate.icon"
        :icon-pack="rate.icon_type"
        @click="$emit('input', id)"
      />
    </b-tooltip>
  </div>
</template>
